import {
  crewBookingRouteGuard,
  reservationBookingGuard,
  reservationAvailabilityGuard,
} from '@/lib/booking/validateStep';

//APPLICATION PATHS
import { bookingPaths } from '@/routes-paths/booking';
import { paths as competitorPaths } from '@/routes-paths/competitor';
import { paths as listingInsightsPaths } from '@/routes-paths/listing-insights';
import { paths as productCatalogPaths } from '@/routes-paths/product-catalog';
import { collaboratorsRoutes } from '@/routes-paths/collaborators';
import { accountGalleryPaths } from '@/routes-paths/account-gallery';
import { ordersPaths } from '@/routes-paths/orders';
import { subscriptionsPaths } from '@/routes-paths/subscriptions';
import { styleQuizPaths } from '@/routes-paths/style-quiz';
import { testPagePaths } from '@/routes-paths/test-pages';

//ROUTE HELPERS
import {
  getIsFeatureFlagActiveGuard,
  signInBeforeEnter,
  substituteAccountId,
} from './route-utils';
import { useQueryClient } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';

//REMAINING APPLICATION COMPONENTS
const PlatformHomePage = () =>
  import('@/components/platform-home/PlatformHomePage.vue');
const SharedGallery = () =>
  import('src/components/SharedGallery/SharedGallery.vue');
const SoonaSignIn = () => import('@/components/authentication/SoonaSignIn.vue');
const SoonaSignUp = () => import('@/components/authentication/SoonaSignUp.vue');
const ShopifyBookingThanksPage = () =>
  import('src/components/booking/v3/p2/shopify/BookingThanks.vue');
const AdminBookings = () =>
  import('src/components/user/anytime/admin/AdminBookings.vue');
const CheckoutLayout = () =>
  import('src/components/checkout/CheckoutLayout.vue');

const AdminTrendSets = () =>
  import('src/components/user/anytime/admin/trend_sets/AdminTrendSets.vue');
const AdminTrendSetBuilder = () =>
  import(
    'src/components/user/anytime/admin/trend_sets/AdminTrendSetBuilder.vue'
  );
const AdminHappeningNow = () =>
  import('src/components/user/anytime/admin/AdminHappeningNow.vue');
const AdminPendingSelects = () =>
  import('src/components/user/anytime/admin/AdminPendingSelects.vue');
const AdminReadyToEdit = () =>
  import('src/components/user/anytime/admin/AdminReadyToEdit.vue');
const AdminCompletedOrders = () =>
  import('src/components/user/anytime/admin/AdminCompletedOrders.vue');
const PromotionsDashboard = () =>
  import(
    'src/components/user/anytime/admin/promotions/PromotionsDashboard.vue'
  );
const CreatePromotion = () =>
  import('src/components/user/anytime/admin/promotions/CreatePromotion.vue');
const UpdatePromotion = () =>
  import('src/components/user/anytime/admin/promotions/UpdatePromotion.vue');
const PromotionsList = () =>
  import('src/components/user/anytime/admin/promotions/PromotionsList.vue');
const TemporaryListingInsightsDashboard = () =>
  import(
    'src/components/user/anytime/admin/temporary_listing_insights/TemporaryListingInsightsDashboard.vue'
  );
const BookingsPage = () => import('src/components/bookings/BookingsPage.vue');
const UserProfile = () =>
  import('src/components/user/anytime/dashboard/Profile.vue');
const ProfileQuiz = () =>
  import('src/components/user/anytime/profile/ProfileQuiz.vue');
const Invitation = () => import('src/components/Invitation.vue');
const EditProfile = () =>
  import('src/components/user/anytime/profile/edit/EditProfile.vue');

const Reschedule = () => import('src/components/reservation/Reschedule.vue');
const RescheduleConfirmation = () =>
  import('src/components/reservation/RescheduleConfirmation.vue');
const Availability = () =>
  import('src/components/user/anytime/dashboard/Availability.vue');
const CrewBookingNew = () =>
  import('src/components/crew/booking/CrewBookingNew.vue');
const CrewBookingEdit = () =>
  import('src/components/crew/booking/CrewBookingEdit.vue');

const CrewPackBuilder = () =>
  import('src/components/crew/pack_builder/PackBuilder.vue');
const CrewPackDashboard = () =>
  import('src/components/crew/pack_builder/PackDashboard.vue');

const CanvaAuth = () =>
  import('src/components/integrations/canva/CanvaAuth.vue');

const ShopifyAuth = () =>
  import('src/components/integrations/shopify/ShopifyAuth.vue');

const ShopifySignIn = () =>
  import('src/components/integrations/shopify/ShopifySignIn.vue');

const CustomerQuestionnaire = () =>
  import(
    'src/components/user/anytime/customer_questionnaire/CustomerQuestionnaire.vue'
  );

/* example route config with authentication meta helpers
  {
    name: 'some route name',
    path: 'some route path',
    meta: {
      requires_auth: true, //bool true or false
      requires_capability: {
        // string if just basic capability or object, do not add if no capability required
        // this can also be an array, of strings or objects, and will allow the route if ANY of the capabilities are satisfied
        capability: 'manage_fastpass',  //required if passing a requires_capability object
        subjectType: 'account',  //string describing capability scope, not required unless scope specific capability
        //a function which accepts the to path and returns the subject id, not required unless subject specific capability
        subjectId: to => to.params.accountId,
        //subjectId can also just be a value
        subjectId: 14
      }
      // add this meta data to preserve scroll position when changing routes
      preserve_scroll_position: true
    }
  }
*/

const routes = {
  routes: [
    {
      path: '/',
      name: 'home',
      component: PlatformHomePage,
      meta: { context: 'home', requires_auth: true, show_quest: true },
    },
    {
      path: '/sign-in',
      name: 'authSignIn',
      component: SoonaSignIn,
      beforeEnter: signInBeforeEnter,
      meta: {
        context: 'sign in',
        routeLayoutOptions: {
          noContainer: true,
        },
      },
      props: route => ({
        isExternalAuthPortal: route.query.isExternalAuthPortal === 'true',
      }),
    },
    {
      path: '/shopify/sign-in',
      name: 'shopifySignIn',
      component: ShopifySignIn,
    },
    {
      path: '/sign-up',
      name: 'authSignUp',
      component: SoonaSignUp,
      beforeEnter: signInBeforeEnter,
      meta: {
        context: 'sign up',
        routeLayoutOptions: {
          noContainer: true,
        },
      },
      props: route => ({
        isExternalAuthPortal: route.query.isExternalAuthPortal === 'true',
        accountCreationSource: route.query.account_creation_source,
        redirectToPath: route.query.redirect,
        referralName: route.query.referral_account_name,
        referralCode: route.query.referral_code,
        token: route.query.token,
      }),
    },
    {
      path: '/integration/sign-in',
      name: 'integrationSignIn',
      component: SoonaSignIn,
      meta: {
        context: 'integration sign in',
        routeLayoutOptions: {
          noContainer: true,
        },
      },
      props: {
        basePath: '/integration',
        showAccountPicker: true,
      },
    },
    {
      path: '/integration/sign-up',
      name: 'integrationSignUp',
      component: SoonaSignUp,
      meta: {
        context: 'integration sign up',
        routeLayoutOptions: {
          noContainer: true,
        },
      },
      props: { basePath: '/integration' },
    },
    {
      path: '/user-settings',
      name: 'user-settings',
      component: () => import('@/components/user/UserSettings.vue'),
      meta: {
        requires_auth: true,
      },
    },
    {
      path: '/shared-gallery/:sharedGalleryHash',
      name: '/shared gallery',
      component: SharedGallery,
      props: route => ({
        sharedGalleryHash: route.params.sharedGalleryHash,
      }),
      meta: {
        context: 'shared gallery',
        routeLayoutOptions: {
          noContainer: true,
        },
      },
    },
    {
      path: '/integrations/canva/auth',
      name: 'canvaAuth',
      component: CanvaAuth,
      meta: {
        requires_flag: 'canva_integration',
        page_title: 'sign in',
      },
    },
    {
      path: '/integrations/shopify/auth',
      name: 'shopifyAuth',
      component: ShopifyAuth,
      meta: {
        page_title: 'sign in',
      },
    },
    ...bookingPaths,
    ...accountGalleryPaths,
    {
      path: '/thank-you',
      name: 'booking thank you',
      component: ShopifyBookingThanksPage,
      props: route => ({ reservationId: route.query.reservation_id }),
      meta: { requires_auth: true, context: 'booking thank you' },
    },
    {
      path: '/admin',
      name: 'admin',
      component: AdminBookings,
      meta: {
        requires_auth: true,
        context: 'bookings',
        page_title: 'bookings admin',
      },
      children: [
        {
          path: '',
          name: 'happeningNow',
          component: AdminHappeningNow,
          meta: { requires_auth: true },
        },
        {
          path: 'pendingSelects',
          name: 'pendingSelects',
          component: AdminPendingSelects,
          meta: {
            requires_auth: true,
          },
        },
        {
          path: 'readyToEdit',
          name: 'readyToEdit',
          component: AdminReadyToEdit,
          meta: {
            requires_auth: true,
          },
        },
        {
          path: 'completedOrders',
          name: 'completedOrders',
          component: AdminCompletedOrders,
          meta: {
            requires_auth: true,
          },
        },
      ],
    },
    {
      path: '/user/:userId',
      name: 'user',
      component: UserProfile,
      meta: {
        requires_auth: true,
        context: 'profile',
        show_quest: true,
      },
    },
    {
      path: '/admin/promotions',
      name: 'promotions',
      component: PromotionsDashboard,
      meta: {
        requires_auth: true,
        requires_capability: 'manage_promotions',
      },
      children: [
        {
          path: '',
          name: 'promotions-list',
          component: PromotionsList,
        },
        {
          path: 'create',
          name: 'createPromotion',
          component: CreatePromotion,
        },
        {
          path: ':promoId/edit',
          name: 'updatePromotion',
          component: UpdatePromotion,
          props: true,
        },
      ],
    },
    {
      path: '/admin/temporary-listing-insights',
      name: 'temporaryListingInsights',
      component: TemporaryListingInsightsDashboard,
      meta: {
        requires_auth: true,
        requires_capability: 'view_temporary_listing_insights_tool',
      },
    },
    {
      path: '/admin/trend-sets',
      name: 'trendSets',
      component: AdminTrendSets,
      meta: {
        requires_auth: true,
        requires_capability: 'manage_trend_sets',
        context: 'trend set builder',
      },
    },
    {
      path: '/admin/trend-sets/:trendSetCategoryId/:trendSetId',
      name: 'trendSetBuilder',
      component: AdminTrendSetBuilder,
      meta: {
        requires_auth: true,
        requires_capability: 'manage_trend_sets',
      },
    },
    {
      path: '/account/%3CaccountId%3E/:remainder*',
      name: 'generic-encoded-account',
      beforeEnter: substituteAccountId,
      meta: {
        requires_auth: true,
      },
    },
    {
      path: '/account/<accountId>/:remainder*',
      name: 'generic-normal-account',
      beforeEnter: substituteAccountId,
      meta: {
        requires_auth: true,
      },
    },
    {
      path: '/account/:accountId',
      name: 'account',
      component: UserProfile,
      props: true,
      meta: {
        requires_auth: true,
        context: 'account dashboard',
        page_title: 'gallery',
      },
      children: [
        {
          path: '',
          name: 'userDashboard',
          props: true,
          meta: {
            requires_auth: true,
            preserve_scroll_position: true,
          },
          beforeEnter: async (to, from, next) => {
            next({
              name: 'account-gallery',
              params: { accountId: to.params.accountId },
            });
          },
        },
        {
          path: 'assignments',
          name: 'assignments',
          component: () =>
            import('src/components/user/anytime/dashboard/Assignments.vue'),
          meta: {
            requires_auth: true,
            preserve_scroll_position: true,
          },
        },
        {
          path: 'crew-tools',
          name: 'crew-tools',
          component: () =>
            import('src/components/user/anytime/dashboard/CrewTools.vue'),
          props: true,
          meta: {
            requires_auth: true,
            preserve_scroll_position: true,
            requires_capability: 'soona_staff',
          },
        },
        {
          path: 'pro-service-profile',
          name: 'pro-service-profile',
          component: () =>
            import(
              'src/components/user/anytime/pro_service/ProServiceProfile.vue'
            ),
          meta: {
            requires_auth: true,
            preserve_scroll_position: true,
          },
        },
        {
          path: 'inventory',
          name: 'inventory',
          component: () =>
            import(
              'src/components/user/anytime/inventory/AccountInventory.vue'
            ),
          meta: {
            requires_auth: true,
            preserve_scroll_position: true,
            requires_capability: 'ft_soona_staff',
          },
        },
        {
          path: 'availability',
          name: 'availability',
          component: Availability,
          meta: {
            requires_auth: true,
            preserve_scroll_position: true,
          },
        },
        {
          path: 'ai-search-test-gallery',
          name: 'ai-search-test-gallery',
          component: () =>
            import('src/components/user/anytime/AiSearchTestGallery.vue'),
          props: true,
          meta: {
            requires_auth: true,
            requires_capability: 'ft_soona_staff',
          },
        },
      ],
    },
    {
      path: '/account/:accountId/bookings',
      name: 'accountBookings',
      component: BookingsPage,
      meta: {
        context: 'account bookings',
        requires_auth: true,
      },
      props: route => ({
        // force the component to unmount and mount again when the account changes
        key: route.params.accountId,
        accountId: route.params.accountId,
      }),
    },
    {
      path: '/returns',
      name: 'returns',
      component: () =>
        import('src/components/user/anytime/shipping/Returns.vue'),
      meta: {
        context: 'return shipping',
        requires_auth: true,
        requires_capability: 'ft_soona_staff',
      },
      children: [
        {
          path: 'return-shipment',
          name: 'return-shipment',
          component: () =>
            import('src/components/user/anytime/shipping/ReturnShipment.vue'),
          meta: {
            requires_auth: true,
          },
        },
        {
          path: 'rates',
          name: 'return-shipping-rates',
          component: () =>
            import('src/components/user/anytime/shipping/ReturnShipment.vue'),
          meta: {
            requires_auth: true,
          },
        },
        {
          path: 'payment',
          name: 'return-shipping-payment',
          component: () =>
            import('src/components/user/anytime/shipping/ReturnPayment.vue'),
          meta: {
            requires_auth: true,
          },
        },
        {
          path: 'completed',
          name: 'return-shipping-completed',
          component: () =>
            import('src/components/user/anytime/shipping/ReturnCompleted.vue'),
          meta: {
            requires_auth: true,
          },
        },
      ],
    },
    {
      path: '/account/:accountId/package/:packageId/edit',
      name: 'edit-package',
      component: () =>
        import(
          'src/components/user/anytime/inventory/EditInventoryPackage.vue'
        ),
      meta: {
        requires_auth: true,
      },
    },
    {
      path: '/account/:accountId/package/:packageId/uploadReturnLabel',
      name: 'upload-return-label',
      component: () =>
        import('src/components/user/anytime/shipping/ReturnOptions.vue'),
      meta: {
        requires_auth: true,
      },
    },
    {
      path: '/account/:accountId/cancel-fast-pass',
      name: 'cancel-fast-pass',
      component: () =>
        import('src/components/user/anytime/fast_pass/CancelFastPass.vue'),
      meta: {
        requires_auth: true,
        requires_capability: {
          capability: 'manage_fastpass',
          subjectType: 'account',
          subjectId: to => to.params.accountId,
        },
      },
    },
    ...collaboratorsRoutes,
    {
      name: 'profile',
      path: '/account/:accountId/profile',
      component: EditProfile,
      meta: {
        requires_auth: true,
        context: 'account settings',
        page_title: 'account profile',
      },
      props: route => ({
        key: route.params.accountId,
        accountId: route.params.accountId,
      }),
    },
    ...ordersPaths,
    ...productCatalogPaths,
    ...competitorPaths,
    ...listingInsightsPaths,
    {
      path: '/account/:accountId/customer-questionnaire',
      component: CustomerQuestionnaire,
      props: route => ({
        accountId: route.params.accountId,
      }),
      meta: {
        requires_auth: true,
      },
    },
    ...subscriptionsPaths,
    {
      path: '/profile-quiz',
      component: ProfileQuiz,
      meta: {
        requires_auth: true,
      },
      children: [
        {
          path: '',
          name: 'customer-profile',
          component: ProfileQuiz,
          meta: {
            requires_auth: true,
          },
        },
      ],
    },
    {
      path: '/album/:collectionId',
      meta: {
        requires_auth: true,
        context: 'album',
      },
      beforeEnter: async (to, from, next) => {
        try {
          const response = await http.get(
            `/collections/${to.params.collectionId}`
          );

          switch (response.data?.type) {
            case 'AnimatedCollection':
              return next({
                name: 'edit-animation',
                params: {
                  accountId: response.data.account_id,
                  collectionId: to.params.collectionId,
                },
                replace: true,
              });
            case 'StaffPicksCollection':
              return next({
                name: 'expert-picks-assets',
                params: {
                  accountId: response.data.account_id,
                  collectionId: to.params.collectionId,
                },
                replace: true,
              });
            case 'FavoritesCollection':
              return next({
                name: 'favorites-assets',
                params: {
                  accountId: response.data.account_id,
                  collectionId: to.params.collectionId,
                },
                replace: true,
              });
            case 'EditsCollection':
              return next({
                name: 'edits-assets',
                params: {
                  accountId: response.data.account_id,
                  collectionId: to.params.collectionId,
                },
                replace: true,
              });
            case 'BagCollection':
              return next({
                name: 'shopping-cart',
                replace: true,
              });
            case 'AlbumCollection':
              return next({
                name: 'album-assets',
                params: {
                  accountId: response.data.account_id,
                  collectionId: to.params.collectionId,
                },
                replace: true,
              });
            default:
              return next({
                name: 'home',
              });
          }
        } catch {
          return next({
            name: 'home',
          });
        }
      },
    },
    {
      path: '/account/:accountId/animation/:collectionId',
      name: 'edit-animation',
      component: () =>
        import('@/components/user/anytime/gifs/EditGifAnimation.vue'),
      meta: {
        requires_auth: true,
        context: 'edit gif',
      },
      props: route => ({
        key: route.params.accountId + route.params.collectionId,
        accountId: route.params.accountId,
        collectionId: route.params.collectionId,
        reservationId: route.query.reservation_id,
      }),
    },
    {
      path: '/account/:accountId/media-editor',
      name: 'media-editor',
      component: () =>
        import('@/components/media-editor/AIStudioLandingPage.vue'),
      meta: {
        context: 'media editor landing',
        requires_auth: true,
        show_quest: true,
      },
      props: route => ({
        // force the component to unmount and mount again when the accountId changes
        key: route.params.accountId,
        accountId: route.params.accountId,
      }),
    },
    {
      path: '/account/:accountId/media-editor/templates',
      name: 'ai-studio-templates',
      component: () =>
        import('@/components/media-editor/AIStudioTemplatesLandingPage.vue'),
      props: route => ({
        // force the component to unmount and mount again when the accountId changes
        key: route.params.accountId + 'ai-studio-templates',
        accountId: route.params.accountId,
      }),
      meta: {
        page_title: 'AI studio templates',
      },
    },
    {
      path: '/account/:accountId/media-editor/template/:templateId',
      name: 'ai-studio-template',
      component: () => import('@/components/media-editor/AIStudioTemplate.vue'),
      props: route => ({
        // force the component to unmount and mount again when the accountId changes
        key:
          route.params.accountId +
          'ai-studio-template-' +
          route.params.templateId,
        accountId: route.params.accountId,
        templateId: route.params.templateId,
      }),
      meta: {
        page_title: 'AI studio template',
      },
    },
    {
      path: '/reservation/:reservationId/editing-summary/order/:orderId',
      children: [
        {
          path: '',
          name: 'order-editing-summary',
          component: () =>
            import(
              '@/components/user/anytime/reservation/edit_history/EditingSummary.vue'
            ),
          props: route => ({
            orderId: route.params.orderId,
            reservationId: route.params.reservationId,
          }),
          meta: {
            context: 'order editing summary',
            requires_auth: true,
            requires_flag: 'toaster_reservation_edit_history',
          },
        },
        {
          path: 'asset/:assetId/compare',
          name: 'order-asset-compare',
          component: () =>
            import(
              '@/components/user/anytime/asset_version_compare/AssetVersionCompare.vue'
            ),
          meta: {
            context: 'order asset comparison',
            requires_auth: true,
            requires_flag: 'toaster_asset_compare',
            routeLayoutOptions: {
              noContainer: true,
              sidebarCollapsed: true,
            },
          },
          props: route => ({
            assetId: route.params.assetId,
            orderId: route.params.orderId,
            reservationId: route.params.reservationId,
          }),
        },
      ],
    },
    {
      path: '/reservation/:reservationId/editing-summary/re-edit/:reEditId',
      children: [
        {
          path: '',
          name: 're-edit-editing-summary',
          component: () =>
            import(
              '@/components/user/anytime/reservation/edit_history/EditingSummary.vue'
            ),
          props: route => ({
            reEditId: route.params.reEditId,
            reservationId: route.params.reservationId,
          }),
          meta: {
            context: 're-edit editing summary',
            requires_auth: true,
            requires_flag: 'toaster_reservation_edit_history',
          },
        },
        {
          path: 'asset/:assetId/compare',
          name: 're-edit-asset-compare',
          component: () =>
            import(
              '@/components/user/anytime/asset_version_compare/AssetVersionCompare.vue'
            ),
          meta: {
            context: 're-edit asset comparison',
            requires_auth: true,
            requires_flag: 'toaster_asset_compare',
            routeLayoutOptions: {
              noContainer: true,
              sidebarCollapsed: true,
            },
          },
          props: route => ({
            assetId: route.params.assetId,
            reEditId: route.params.reEditId,
            reservationId: route.params.reservationId,
          }),
        },
      ],
    },
    {
      path: '/reservation/:reservationId',
      component: () =>
        import(
          'src/components/user/anytime/reservation/ReservationBooking.vue'
        ),
      meta: {
        requires_auth: true,
        context: 'reservation',
        page_title: 'reservation',
      },
      props: route => ({
        reservationId: route.params.reservationId,
      }),
      beforeEnter: async to => {
        // todo: stale time of a few seconds
        return reservationBookingGuard(to);
      },
      children: [
        {
          path: '',
          name: 'gallery',
          component: () =>
            import(
              'src/components/user/anytime/reservation/ReservationGallery.vue'
            ),
          props: route => ({
            reservationId: route.params.reservationId,
            filter: route.query?.filter,
          }),
          meta: {
            context: 'reservation gallery',
            requires_auth: true,
            chatIsInGallery: true,
          },
        },
        {
          path: 'info',
          name: 'info',
          component: () =>
            import('src/components/user/anytime/details/Info.vue'),
          props: true,
          meta: {
            context: 'reservation info',
            requires_auth: true,
          },
        },
        {
          path: 'shotlist',
          name: 'shotlist',
          component: () =>
            import('@/components/user/anytime/shotlist/ShotList.vue'),
          meta: {
            context: 'reservation shot list',
            requires_auth: true,
          },
        },
        {
          path: 'pro-service-traits',
          name: 'pro-service-traits',
          component: () =>
            import('src/components/user/anytime/ProServiceTraits.vue'),
          meta: {
            context: 'reservation pro services',
            requires_auth: true,
          },
        },
        {
          path: 'edit-history',
          name: 'edit-history',
          component: () =>
            import(
              'src/components/user/anytime/reservation/edit_history/EditHistory.vue'
            ),
          meta: {
            context: 'reservation edit history',
            requires_auth: true,
            requires_flag: 'toaster_reservation_edit_history',
          },
          props: route => ({
            reservationId: route.params.reservationId,
          }),
        },
        {
          // needs to exist in perpetuity to handle redirects
          path: 'media/:mediaId',
          name: 'reservation-media-view',
          beforeEnter: async (to, from, next) => {
            try {
              const response = await http.get(
                `/media/gallery_files/${to.params.mediaId}`
              );
              if (response.data?.digital_asset_id) {
                return next({
                  name: 'reservation-asset-view',
                  params: {
                    reservationId: to.params.reservationId,
                    digitalAssetId: response.data.digital_asset_id,
                  },
                  query: to.query,
                  replace: true,
                });
              } else {
                return next({
                  name: 'home',
                });
              }
            } catch {
              return next({
                name: 'home',
              });
            }
          },
        },
        {
          path: 'asset/:digitalAssetId',
          name: 'reservation-asset-view',
          component: () =>
            import(
              '@/components/user/anytime/reservation/editor/BookingAssetEditor.vue'
            ),
          meta: {
            requires_auth: true,
            context: 'media editor',
          },
          props: route => ({
            reservationId: route.params.reservationId,
            digitalAssetId: route.params.digitalAssetId,
            filter: route.query.filter,
          }),
        },
        {
          path: 'hidden-edits/asset/:digitalAssetId',
          name: 'reservation-asset-hidden-edit-view',
          component: () =>
            import(
              '@/components/user/anytime/reservation/editor/BookingHiddenEditsEditor.vue'
            ),
          meta: {
            requires_auth: true,
            context: 'media editor',
          },
          props: route => ({
            reservationId: route.params.reservationId,
            digitalAssetId: route.params.digitalAssetId,
          }),
        },
        {
          path: 'edits/asset/:digitalAssetId',
          name: 'reservation-asset-edit-view',
          component: () =>
            import(
              '@/components/user/anytime/reservation/editor/BookingEditsEditor.vue'
            ),
          meta: {
            requires_auth: true,
            context: 'media editor',
          },
          props: route => ({
            reservationId: route.params.reservationId,
            digitalAssetId: route.params.digitalAssetId,
          }),
        },
        {
          path: 'crew/edits',
          name: 'crew-edits',
          component: () =>
            import(
              '@/components/user/anytime/reservation/crew_edits/EditsGallery.vue'
            ),
          meta: {
            requires_auth: true,
            requires_capability: 'soona_staff',
          },
          props: route => ({
            reservationId: route.params.reservationId,
            filter: route.query.filter,
          }),
        },
        {
          path: 'crew/edits/:digitalAssetId',
          name: 'crew-edits-asset-view',
          component: () =>
            import(
              '@/components/user/anytime/reservation/crew_edits/EditDetailsEditor.vue'
            ),
          meta: {
            requires_auth: true,
            requires_capability: 'soona_staff',
          },
          props: route => ({
            digitalAssetId: route.params.digitalAssetId,
            reservationId: route.params.reservationId,
            filter: route.query.filter,
          }),
        },
        {
          path: 're_edits/asset/:digitalAssetId',
          name: 'reservation-asset-re-edit-view',
          component: () =>
            import(
              '@/components/user/anytime/reservation/editor/BookingReEditsEditor.vue'
            ),
          meta: {
            requires_auth: true,
            context: 'media editor',
          },
          props: route => ({
            reservationId: route.params.reservationId,
            digitalAssetId: route.params.digitalAssetId,
          }),
        },
        {
          path: 'shipping',
          component: () => import('src/components/user/anytime/Shipping.vue'),
          meta: {
            context: 'shipping',
            requires_auth: true,
          },
          children: [
            {
              path: '',
              name: 'shipping-options',
              component: () =>
                import('src/components/user/anytime/shipping/Options.vue'),
              meta: {
                page_title: 'shipping options',
                requires_auth: true,
              },
            },
            {
              path: 'shipment',
              name: 'shipping-shipment',
              component: () =>
                import('src/components/user/anytime/shipping/Shipment.vue'),
              meta: {
                page_title: 'shipment',
                requires_auth: true,
              },
            },
            {
              path: 'confirm',
              name: 'shipping-confirm',
              component: () =>
                import('src/components/user/anytime/shipping/Confirm.vue'),
              meta: {
                page_title: 'shipping checkout',
                requires_auth: true,
              },
            },
            {
              path: 'rates',
              name: 'shipping-rates',
              component: () =>
                import('src/components/user/anytime/shipping/Rates.vue'),
              meta: {
                page_title: 'shipping rates',
                requires_auth: true,
              },
            },
            {
              path: 'payment',
              name: 'shipping-payment',
              component: () =>
                import('src/components/user/anytime/shipping/Payment.vue'),
              meta: {
                page_title: 'shipping checkout',
                requires_auth: true,
              },
            },
            {
              path: 'completed',
              name: 'shipping-completed',
              component: () =>
                import('src/components/user/anytime/shipping/Completed.vue'),
              meta: {
                page_title: 'shipping completed',
                requires_auth: true,
              },
            },
          ],
        },
        {
          path: 'crew',
          name: 'crew',
          component: () => import('src/components/user/anytime/note/Crew.vue'),
          props: true,
          meta: {
            requires_auth: true,
            requires_capability: 'soona_staff',
          },
        },
        {
          path: 'availability',
          name: 'reservation-availability',
          component: () =>
            import('src/components/user/anytime/details/Availability.vue'),
          meta: {
            context: 'reservation availability',
            page_title: 'availability',
            requires_auth: true,
          },
          beforeEnter: async to => {
            // vue router injects global providers into global route guards
            const queryClient = useQueryClient();
            const isFlagActive = await getIsFeatureFlagActiveGuard(queryClient);
            if (isFlagActive('pegasus_availability_csr')) {
              return reservationAvailabilityGuard(to);
            }
            return false;
          },
        },
        // kept for old links
        {
          path: 'orders/:orderId/checkout',
          name: 'checkout-old',
          component: () =>
            import(
              'src/components/user/anytime/AdditionalChargeOrderSummary.vue'
            ),
          meta: {
            requires_auth: true,
          },
        },
      ],
    },
    {
      path: '/reservation/:reservationId/additional-order',
      name: 'reservation-additional-order',
      component: () =>
        import('src/components/user/anytime/ReservationAdditionalOrder.vue'),
      meta: {
        context: 'reservation additional order',
        requires_auth: true,
        routeLayoutOptions: {
          noContainer: true,
        },
      },
      props: route => ({
        // force the component to unmount and mount again when the reservationId changes
        key: route.params.reservationId,
        reservationId: route.params.reservationId,
      }),
    },
    {
      path: '/reservation/:reservationId/shoot-products',
      name: 'shoot-products',
      component: () =>
        import('src/components/user/anytime/ReservationShootProducts.vue'),
      meta: {
        page_title: 'shoot products | soona',
        context: 'shoot products',
        requires_auth: true,
      },
      props: route => ({
        // force the component to unmount and mount again when the reservationId changes
        key: route.params.reservationId,
        reservationId: route.params.reservationId,
      }),
    },
    {
      path: '/reservation/:reservationId/shoot-upgrades',
      name: 'shoot-upgrades',
      component: () =>
        import(
          'src/components/user/anytime/self_serve_purchases/ReservationShootUpgrades.vue'
        ),
      meta: {
        page_title: 'shoot upgrades | soona',
        context: 'self-serve purchase',
        requires_auth: true,

        routeLayoutOptions: {
          noContainer: true,
        },
      },
      props: route => ({
        // force the component to unmount and mount again when the reservationId changes
        key: route.params.reservationId,
        reservationId: route.params.reservationId,
      }),
    },
    {
      path: '/reservation/:reservationId/shoot-summary',
      name: 'shoot-summary',
      component: () =>
        import('src/components/user/anytime/ReservationShootSummary.vue'),
      meta: {
        page_title: 'shoot summary | soona',
        context: 'shoot summary',
        requires_auth: true,
      },
      props: route => ({
        // force the component to unmount and mount again when the reservationId changes
        key: route.params.reservationId,
        reservationId: route.params.reservationId,
      }),
    },
    {
      name: 'shopping-cart',
      path: '/bag',
      component: CheckoutLayout,
      meta: {
        context: 'bag',
        requires_auth: true,
      },
    },
    {
      name: 'Thanks',
      path: '/thanks',
      component: () => import('src/components/user/anytime/Thanks.vue'),
      meta: {
        context: 'thanks',
        requires_auth: true,
      },
    },
    {
      name: 'shipping_summary',
      path: '/shipping_summary',
      component: () =>
        import('src/components/user/anytime/ShippingSummary.vue'),
      meta: {
        requires_auth: true,
        requires_capability: 'ft_soona_staff',
        page_title: 'shipping summary',
      },
    },
    ...styleQuizPaths,
    {
      path: '/checkout/:orderId',
      name: 'checkout',
      component: () =>
        import('src/components/user/anytime/AdditionalChargeOrderSummary.vue'),
      meta: {
        context: 'additional charge order checkout',
        requires_auth: true,
      },
      props: route => ({
        // force the component to unmount and mount again when the order id changes
        key: route.params.orderId,
        orderId: route.params.orderId,
      }),
    },
    {
      path: '/invitation/:invitationId',
      name: 'invitation',
      component: Invitation,
      meta: {
        requires_auth: true,
      },
      props: route => ({
        bookingId: route.query.booking_id,
        errorMessage: route.query.error,
        invitationId: route.params.invitationId,
        token: route.query.token,
      }),
    },
    {
      path: '/reschedule/:reservationId',
      name: 'reschedule',
      component: Reschedule,
      meta: {
        requires_auth: true,
      },
    },
    {
      path: '/reschedule/:reservationId/confirmed',
      name: 'reschedule-confirmed',
      component: RescheduleConfirmation,
      meta: {
        requires_auth: true,
      },
    },
    {
      path: '/crew/booking/new',
      name: 'crew-booking-new',
      component: CrewBookingNew,
      meta: {
        requires_auth: true,
        requires_capability: 'manage_crew_booking_builder',
      },
      props: route => ({
        accountId: route.query.account_id,
        type: route.query.type,
        draftType: route.query.draftType,
        previousReservationId: route.query.previous_reservation_id,
        copyPackages: route.query.inventory_packages,
        copyShotList: route.query.shot_list,
      }),
    },
    {
      path: '/crew/booking/:reservationId/edit',
      name: 'crew-booking-edit',
      component: CrewBookingEdit,
      meta: {
        requires_auth: true,
        requires_capability: 'manage_crew_booking_builder',
        routeLayoutOptions: {
          noContainer: true,
        },
      },
      props: true,
      beforeEnter: to => {
        return crewBookingRouteGuard(to);
      },
    },
    {
      path: '/crew/bag/:accountId',
      name: 'crew-bag-preview',
      component: () => import('src/components/crew/CrewBagPreview.vue'),
      meta: {
        requires_auth: true,
      },
      props: true,
    },
    {
      path: '/crew/packs/',
      name: 'crew-pack-dashboard',
      component: CrewPackDashboard,
      meta: {
        requires_auth: true,
        requires_capability: 'manage_packs',
      },
      props: true,
    },
    {
      path: '/crew/packs/:packConfigurationId/edit',
      name: 'crew-packs-edit',
      component: CrewPackBuilder,
      meta: {
        requires_auth: true,
        requires_capability: 'manage_packs',
      },
      props: true,
    },
    {
      path: '/crew/orders/:orderId/',
      name: 'crew-order',
      component: () => import('src/components/crew/orders/AddOnOrderPage.vue'),
      meta: {
        requires_auth: true,
      },
      props: true,
    },
    {
      path: '/crew/orders/:orderId/add-on-line-items/:addOnLineItemId',
      name: 'crew-order-add-on-line-item',
      component: () => import('@/components/crew/orders/AddOnLineItemPage.vue'),
      meta: {
        requires_auth: true,
        routeLayoutOptions: {
          noContainer: true,
        },
      },
      props: true,
    },
    ...testPagePaths,
    {
      path: '/schedule',
      name: 'schedule',
      component: () => import('@/components/schedule/Schedule.vue'),
      meta: {
        requires_auth: true,
        requires_capability: 'ft_soona_staff',
        routeLayoutOptions: {
          noContainer: true,
        },
      },
    },
    {
      path: '/directory',
      name: 'directory',
      component: () => import('@/components/directory/Directory.vue'),
      meta: {
        context: 'provider directory',
        requires_auth: true,
        routeLayoutOptions: {
          noContainer: true,
        },
      },
    },
    {
      path: '/discovery',
      name: 'discovery',
      component: () => import('@/components/discovery/DiscoveryPage.vue'),
      meta: {
        context: 'discovery',
        requires_auth: true,
        requires_flag: 'apollo_discovery_page',
        show_quest: true,
      },
    },
    {
      path: '/user-generated-content',
      name: 'user-generated-content-landing',
      component: () =>
        import(
          '@/components/user-generated-content/UserGeneratedContentPage.vue'
        ),
      meta: {
        context: 'user-generated-content-landing',
        requires_auth: true,
        requires_flag: 'toaster_enable_ugc_trend_page',
        show_quest: true,
      },
    },
  ],
  add: function (component) {
    this.routes.push({
      path: component.path,
      children: component.children,
      name: component.name,
      component: component,
    });
  },
};

export default routes;
