<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useRoute } from 'vue-router';
import { trendHeader1, trendHeader2 } from '@/lib/vimeo-videos';

const emit = defineEmits(['create-brief-click']);

const route = useRoute();
const { linkClicked } = useBaseEvents();
const subContext = 'hero banner';

const trendLogInUrl =
  import.meta.env.VITE_TREND_APP_URL +
  '?utm_source=soona&utm_medium=referral&utm_campaign=trend_log_in_book_soona&utm_content=hero_banner';

function onBriefClick() {
  linkClicked({
    context: route.meta.context,
    subContext,
    linkLabel: 'create a brief',
    linkHref: null,
  });

  emit('create-brief-click');
}

const vimeoParams = new URLSearchParams({
  title: 0,
  byline: 0,
  portrait: 0,
  responsive: true,
  background: true,
});

const videoOneSrc = `https://player.vimeo.com/video/${
  trendHeader1.vimeoId
}?${vimeoParams.toString()}${trendHeader1.additionalParams}`;

const videoTwoSrc = `https://player.vimeo.com/video/${
  trendHeader2.vimeoId
}?${vimeoParams.toString()}${trendHeader2.additionalParams}`;
</script>

<template>
  <div class="trend-header">
    <div class="trend-header__inner">
      <div role="presentation" class="trend-header__inner__images-left">
        <div class="trend-header__inner__iframe-wrap">
          <iframe
            :src="videoOneSrc"
            allow="fullscreen; picture-in-picture"
            allowfullscreen
            loading="lazy"
          />
        </div>
        <img
          src="@images/trend/UGC_header_happy_flo@2x.jpg"
          alt="presenting a vitamin"
          class="trend-header__inner__image-square"
        />
      </div>
      <div class="trend-header__inner__content">
        <img
          src="@images/trend-by-soona.svg"
          alt="Trend by soona"
          width="131"
          height="37"
          style="width: 8.1875rem; height: 2.3125rem"
        />
        <h1 class="u-display--heavy">UGC helps brands grow</h1>
        <p class="u-subheader--regular">
          82% of brands will invest in user generated content in 2024
        </p>
        <div class="trend-header__inner__content__cta">
          <img
            src="@images/down-right-arrow.svg"
            alt=""
            width="61"
            height="47"
            class="trend-header__inner__content__cta__arrow-left"
          />
          <SoonaButton
            variation="solid-black"
            size="large"
            class="trend-header__inner__content__brief-button"
            @on-click="onBriefClick"
          >
            create a free brief
          </SoonaButton>
          <img
            src="@images/down-right-arrow.svg"
            alt=""
            width="61"
            height="47"
            class="trend-header__inner__content__cta__arrow-right"
          />
        </div>
        <p class="u-body--heavy">
          10% off your first order with code:
          <span style="user-select: all">firstcontent10</span>
        </p>
        <p>
          already have an account?
          <a
            :href="trendLogInUrl"
            target="_blank"
            class="trend-header__inner__content__log-in-link u-body--heavy"
            @click="
              linkClicked({
                context: route.meta.context,
                subContext,
                linkLabel: 'log in',
                linkHref: trendLogInUrl,
              })
            "
          >
            log&nbsp;in
            <span class="u-visually-hidden">to Trend</span>
          </a>
        </p>
      </div>
      <div role="presentation" class="trend-header__inner__images-right">
        <div class="trend-header__inner__iframe-wrap">
          <iframe
            :src="videoTwoSrc"
            allow="fullscreen; picture-in-picture"
            allowfullscreen
            loading="lazy"
          />
        </div>
        <img
          src="@images/trend/UGC_header_jumper@2x.jpg"
          alt="jumping in the mountains"
          class="trend-header__inner__image-square"
        />
      </div>
    </div>
    <ol class="trend-header__steps u-body--heavy" aria-label="short steps">
      <li>create a free brief that outlines your needs</li>
      <li>choose creators that fit your brand image</li>
      <li>get creative & use it anywhere you want to</li>
    </ol>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.trend-header {
  background-color: variables.$green-apple-30;
  border: 0.0625rem solid variables.$black-default;
  border-radius: 1rem;
  box-shadow: 0 0.375rem 0 0 variables.$black-default;
  margin-bottom: 0.375rem;

  &__inner {
    background-color: variables.$green-apple-20;
    border-radius: 0.9375rem;
    padding: 2rem 1rem;
    box-shadow: 0 0.0625rem 0 0 variables.$black-default;
    margin-bottom: 0.0625rem;
    display: flex;
    gap: 2.5rem;
    align-items: center;
    justify-content: center;

    &__image-square {
      aspect-ratio: 1/1;
    }

    &__iframe-wrap {
      border-radius: 1rem;
      border: 0.0625rem solid variables.$black-default;
      background-color: variables.$gray-10;

      & iframe {
        display: block;
        border-radius: 0.9375rem;
        aspect-ratio: 9/16;
        min-width: 0;
        width: 100%;
      }
    }

    &__images-left,
    &__images-right {
      display: none;
      position: relative;
      flex: 1 1 12rem;

      & img {
        display: block;
        border-radius: 1rem;
        border: 0.0625rem solid variables.$black-default;
        background-color: variables.$gray-10;
        object-fit: cover;

        &:last-child {
          position: absolute;
          width: 50%;
          height: auto;
        }
      }
    }

    &__images-left {
      /* no margin on the right */
      margin: 1rem 0 1rem 1rem;
      padding-bottom: 1.5rem;
      padding-right: 2.5rem;

      img:last-child {
        right: 0;
        bottom: 0;
      }
    }

    &__images-right {
      /* no margin on the left */
      margin: 1rem 1rem 1rem 0;
      padding-top: 1.5rem;
      padding-left: 2.5rem;

      img:last-child {
        top: 0;
        left: 0;
      }
    }

    &__content {
      /* grow/shrink the center content 4x more than the sides */
      flex: 4 4 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      text-align: center;

      &__cta {
        display: flex;
        gap: 2.5rem;
        align-items: center;
        margin-block-end: 1rem;

        &__arrow-left,
        &__arrow-right {
          display: none;
          height: 3.8125rem;
          width: 2.9375rem;
        }

        &__arrow-right {
          transform: scaleX(-1);
        }
      }

      &__brief-button {
        margin-block-start: 1.5rem;
      }

      &__log-in-link {
        text-decoration: underline;
      }
    }
  }

  &__steps {
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    row-gap: 1.75rem;
    list-style: none;
    counter-reset: trend-steps-counter;
    padding: 1.5rem 1rem;

    li {
      counter-increment: trend-steps-counter;
      display: flex;
      gap: 0.75rem;
      align-items: center;

      &::before {
        content: counter(trend-steps-counter);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 2.75rem;
        background-color: variables.$black-default;
        border-radius: 10rem;
        padding: 0.25rem;
        font-size: 1rem;
        height: 2.75rem;
        width: 2.75rem;
        color: variables.$white-default;
      }
    }
  }
}

@mixin trend-header-show-arrows-styles {
  .trend-header__inner__content__cta__arrow-left,
  .trend-header__inner__content__cta__arrow-right {
    display: block;
  }
}

@container ugc-page (min-width: 24rem) {
  @include trend-header-show-arrows-styles;
}

@mixin trend-header-steps-list-large-styles {
  .trend-header__steps {
    flex-direction: row;
    justify-content: space-evenly;

    li {
      flex: 0 1 14rem;
    }
  }
}

@container ugc-page (min-width: 46rem) {
  @include trend-header-steps-list-large-styles;
}

@mixin trend-header-large-styles {
  .trend-header__inner {
    justify-content: space-between;
  }

  .trend-header__inner__images-left,
  .trend-header__inner__images-right {
    display: block;
  }
}

@container ugc-page (min-width: 54rem) {
  @include trend-header-large-styles;
}

/* compat: remove when dropping Safari 15 */
@supports not (container-type: inline-size) {
  @media (min-width: 54rem) {
    @include trend-header-steps-list-large-styles;
    @include trend-header-large-styles;
  }

  @media (min-width: 74rem) {
    @include trend-header-show-arrows-styles;
  }
}
</style>
