<script setup>
import { computed, toRefs } from 'vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useIntegrations } from '@/composables/useIntegrations';
import { useCatalogItem } from 'src/queries/useCatalog';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  catalogItemId: {
    type: [String, Number],
    required: true,
  },
  selectedIntegrationId: {
    type: [String, Number, null],
    required: true,
  },
});

defineEmits(['select']);

const { accountId, catalogItemId, selectedIntegrationId } = toRefs(props);

const { data: productData } = useCatalogItem(accountId, catalogItemId);

const { integrations } = useIntegrations(accountId);

const selectedIntegration = computed(() => {
  return integrations.value.find(
    integration =>
      Number(integration.id) === Number(selectedIntegrationId.value)
  );
});

function integrationName(integration) {
  if (integration.type === 'AmazonIntegration') {
    return 'Amazon';
  } else if (integration.type === 'ShopifyIntegration') {
    let store = integration.external_provider_id;
    store = store.replaceAll('-', ' ');
    store = store.replaceAll('.myshopify.com', ' ');
    store = store.replaceAll('admin.shopify.com/store/', ' ');
    return store;
  }
}

const integrationLogoLookup = {
  ShopifyIntegration: 'shopify-logo',
  AmazonIntegration: 'amazon-logo',
};
</script>

<template>
  <SoonaDropdownMenu
    title="choose integration"
    variation="secondary-gray"
    size="large"
  >
    <template #trigger-content>
      <SoonaIcon
        :name="integrationLogoLookup[selectedIntegration.type]"
        size="medium"
      />
      <span class="integration-name">{{
        integrationName(selectedIntegration)
      }}</span>
    </template>
    <template #default="{ keydown, mouseover, clickCapture }">
      <SoonaDropdownMenuItem
        v-for="ep in productData?.external_products"
        :key="ep.id"
      >
        <button
          class="integration-select-btn"
          role="menuitem"
          @click="() => $emit('select', ep)"
          @click.capture="clickCapture"
          @keydown="keydown"
          @mouseover="mouseover"
        >
          <SoonaIcon
            :name="integrationLogoLookup[ep.integration.type]"
            size="medium"
          />
          <span class="integration-name">{{
            integrationName(ep.integration)
          }}</span>
          <SoonaIcon
            v-if="ep.integration.id === Number(selectedIntegrationId)"
            class="integration-select-btn__icon"
            name="check"
            size="medium"
          />
        </button>
      </SoonaDropdownMenuItem>
    </template>
  </SoonaDropdownMenu>
</template>

<style lang="scss" scoped>
@use '@/variables';

.integration-name {
  text-transform: capitalize;
}

.integration-select-btn {
  display: flex;
  text-transform: none;
  gap: 0.5rem;
  align-items: center;

  &--bold {
    font-weight: 700;
  }

  &__icon {
    color: variables.$periwink-blue-60;
    margin-left: auto;
  }
}
</style>
