<script setup>
import { computed, ref, toRefs } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useIntegrations } from '@/composables/useIntegrations';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useRoute, useRouter } from 'vue-router';
import ReconnectAmazonDialog from '@/components/user/anytime/products/ReconnectAmazonDialog.vue';
import SelectIntegrationListing from './SelectIntegrationListing.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  integrationId: {
    type: String,
    required: true,
  },
  listing: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['refreshListing']);
const route = useRoute();
const router = useRouter();
dayjs.extend(relativeTime);

const { accountId, integrationId, listing } = toRefs(props);

const { currentIntegrationType, currentPlatformName, currentIntegration } =
  useIntegrations(accountId, integrationId);

const isAmazonIntegration = computed(
  () => currentIntegrationType.value === 'AmazonIntegration'
);

const displayRefreshButton = computed(() =>
  ['Amazon', 'Shopify'].includes(currentPlatformName.value)
);

const listingUrl = computed(
  () => `https://www.amazon.com/dp/${listing.value.catalog_item_info?.asin}`
);

const showReconnectAmazonDialog = ref(false);

const refreshListing = () => {
  if (
    currentPlatformName.value === 'Amazon' &&
    currentIntegration.value?.status === 'invalid_state'
  ) {
    showReconnectAmazonDialog.value = true;
    return;
  }

  emits('refreshListing');
};

const selectIntegrationListing = externalProduct => {
  router.push({
    name: 'listing-optimize',
    params: {
      accountId: accountId.value,
      productId: route.params.productId,
      externalProductId: externalProduct.id,
      integrationId: externalProduct.integration_id,
    },
  });
};
</script>

<template>
  <div class="current-integration">
    <div class="current-integration__listing">
      <SelectIntegrationListing
        :account-id="accountId"
        :selected-integration-id="integrationId"
        :catalog-item-id="listing.catalog_item_id"
        @select="selectIntegrationListing"
      />
      <a
        v-if="isAmazonIntegration"
        :href="listingUrl"
        class="current-integration__link"
        target="_blank"
      >
        view listing
        <div>
          <SoonaIcon
            class="current-integration__link--icon"
            name="arrow-up-right-from-square"
            size="small"
          />
        </div>
      </a>
    </div>
    <div class="current-integration__refresh">
      <p class="u-small--regular">
        updated {{ dayjs().to(dayjs(listing.updated_at)) }}
      </p>
      <SoonaButton
        v-if="displayRefreshButton"
        variation="icon-plain-gray"
        size="small"
        title="refresh listing"
        @click="refreshListing"
      >
        <SoonaIcon name="refresh-cw-alt" />
      </SoonaButton>
    </div>
  </div>
  <ReconnectAmazonDialog
    v-if="showReconnectAmazonDialog"
    @close="showReconnectAmazonDialog = false"
  />
</template>

<style lang="scss" scoped>
@use '@/variables';

.current-integration {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;

  &__listing {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    align-items: center;
  }

  &__link {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &__refresh {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-self: flex-end;
  }
}
</style>
