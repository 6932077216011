<template>
  <div class="AdditionalChargeOrderSummary">
    <SoonaError v-if="paymentError" no-margin>
      {{ paymentError }}
    </SoonaError>
    <template v-if="isSuccess && unpaidAdditionalChargeOrder">
      <section>
        <PaymentInterstitial v-if="showInterstitial">
          <template #heading>
            {{ paymentHeading }}
          </template>
          <template v-if="!isPreferredOrder" #bold-subheading>
            now hold tight...
          </template>
          <template #subheading>
            {{ paymentSubheading }}
          </template>
          <template v-if="isPreferredOrder" #payment-description>
            <SoonaButton element="a" href="/book/new">book now</SoonaButton>
            <p>or go <a href="/#/">home</a></p>
          </template>
          <template v-else #payment-description>
            <p>or</p>
            <SoonaButton element="a" href="/book/new">book again</SoonaButton>
          </template>
        </PaymentInterstitial>
        <SelfServeCheckout
          v-else
          :account-id="unpaidAdditionalChargeOrder?.account?.id"
          :discount-obj="unpaidAdditionalChargeOrder?.discount"
          :order-id="orderId"
          :is-comped-order="isFree"
          :products="products"
          :place-order="placeOrder"
          :is-order-processing="isOrderProcessing"
          :is-preferred-order="isPreferredOrder"
          :totals="unpaidAdditionalChargeOrder?.totals"
          :can-view-payment-info="canViewPaymentInfo"
          :reservation-id-for-discount="
            unpaidAdditionalChargeOrder?.reservation_id
          "
          :show-promo-code="!!unpaidAdditionalChargeOrder?.reservation_id"
          @back="previousPage"
          @order-declined="handleCancel"
        >
          <template #product-info="product">
            <div
              v-if="product.product.name.includes('Preferred')"
              class="credit-info"
            >
              <p class="credit-info__disclaimer">
                credits sold in quantities of 50
              </p>
              <hr />
              <p class="credit-info__header">includes</p>
              <div>
                <div
                  v-for="attribute in tierAttributes(product.product)"
                  :key="attribute"
                  class="attribute"
                >
                  <SoonaIcon name="check" :color="AvoToast60" />
                  <p>{{ attribute }}</p>
                </div>
              </div>
              <p class="credit-info__disclaimer">
                credits are valid for one year after date of purchase.
              </p>
            </div>
          </template>
        </SelfServeCheckout>
      </section>
      <AdditionalChargeOrderMemo
        v-if="unpaidAdditionalChargeOrder.memo"
        :unpaid-additional-charge-order="unpaidAdditionalChargeOrder"
      />
      <SoonaDialog v-if="showDeclineConfirm" @close="closeModal">
        <div class="confirmation-modal-card">
          <div class="has-text-centered">
            <slot name="icon"></slot>
            <h3 class="u-display--heavy is-size-5-mobile">
              <slot name="header" />
              order canceled
            </h3>
            <p class="is-size-7-mobile">
              <slot name="body"
                >we'll let the crew know you declined this order!
              </slot>
            </p>
          </div>
          <div class="button-row has-text-centered mt-m">
            <SoonaButton :disabled="canceling" :on-click="closeModal">
              got it
            </SoonaButton>
          </div>
        </div>
      </SoonaDialog>
    </template>
    <div v-else-if="error" class="column is-12">
      <section>
        <br />
        <br />
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-8">
              <div class="notification is-8">
                <h1 class="title">sorry, something went wrong…</h1>
                <div class="columns is-mobile">
                  <div v-if="error.response.status == 403" class="column is-12">
                    this order is private. if someone gave you this link, you'll
                    need to sign into an account which has access to this order.
                  </div>
                  <div v-if="error.response.status == 404" class="column is-12">
                    we couldn't find your order, if this continues to happen
                    please reach out!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { computed, onUnmounted, ref } from 'vue';
import { mapActions, useStore } from 'vuex';
import SelfServeCheckout from '@/components/user/anytime/self_serve_purchases/SelfServeCheckout.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import PaymentInterstitial from 'src/components/booking/v3/p2/PaymentInterstitial.vue';
import AdditionalChargeOrderMemo from './AdditionalChargeOrderMemo.vue';
import { AvoToast60 } from 'src/variables.module.scss';
import { useTimeoutFn } from '@vueuse/core';
import * as Sentry from '@sentry/vue';
import { useIntegrations } from '@/composables/useIntegrations';
import { useFlag } from '@/composables/useFlag';
import { useMe } from '@/composables/user/useMe';
import { useOrder } from '@/queries/orders/useOrder';
import { useSalesTaxStore } from '@/components/user/anytime/billing_and_orders/store/useSalesTaxStore';
import { storeToRefs } from 'pinia';

export default {
  name: 'AdditionalChargeOrderSummary',
  components: {
    SelfServeCheckout,
    SoonaButton,
    SoonaDialog,
    SoonaError,
    SoonaIcon,
    PaymentInterstitial,
    AdditionalChargeOrderMemo,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const paymentError = ref('');
    const { currentAccountId } = useMe();
    const store = useStore();
    const salesTaxStore = useSalesTaxStore();

    const orderId = computed(() => props.orderId);

    const { hasShopifyIntegration } = useIntegrations(currentAccountId);

    const {
      salesTaxBillingAddressId,
      stripeSalesTaxCalculationId,
      taxAmountExclusiveInCents,
    } = storeToRefs(salesTaxStore);
    onUnmounted(() => {
      salesTaxStore.$reset();
    });

    const paymentsPaymentMethodsFlag = useFlag('payments_payment_methods');
    const paymentSalesTaxFlag = useFlag('payments_sales_tax');

    const {
      data: unpaidAdditionalChargeOrder,
      error,
      isSuccess,
    } = useOrder(
      orderId,
      {},
      {
        enabled: computed(() => !!orderId.value),
      }
    );

    const currentOrder = computed(() => unpaidAdditionalChargeOrder.value);

    const isInternal = computed(
      () => store.state.currentReservation?.reservation_type === 'internal'
    );

    const isFree = computed(() => {
      return (
        isInternal.value ||
        unpaidAdditionalChargeOrder.value?.payment_provider === 'comp'
      );
    });

    const isPreferredOrder = computed(() => {
      return (
        unpaidAdditionalChargeOrder.value?.['contains_preferred_products?'] ??
        false
      );
    });

    const orderTotal = computed(() => {
      let price_modifier = 1;
      if (isFree.value) {
        price_modifier = 0;
      }
      if (unpaidAdditionalChargeOrder.value?.reservation_line_items) {
        return unpaidAdditionalChargeOrder.value.reservation_line_items.reduce(
          (sum, lineItem) =>
            sum + lineItem.product.rate * price_modifier * lineItem.quantity,
          0
        );
      } else {
        return unpaidAdditionalChargeOrder.value?.amount_collected;
      }
    });

    const isOrderTotalZero = computed(() => orderTotal.value === 0);

    const { start: startInterstitialTimeout } = useTimeoutFn(
      () => {
        window.location.assign('/#/');
      },
      6500,
      { immediate: false }
    );

    const paymentHeading = computed(() => {
      return isFree.value || isOrderTotalZero.value
        ? 'thank you!'
        : 'payment received!';
    });

    const paymentSubheading = computed(() => {
      if (isPreferredOrder.value) {
        return 'start using your credits today & check your email for preferred program details.';
      } else {
        return 'you’ll be redirected to your dashboard.';
      }
    });

    return {
      currentAccountId,
      currentOrder,
      error,
      paymentError,
      isSuccess,
      hasShopifyIntegration,
      isFree,
      isInternal,
      isOrderTotalZero,
      isPreferredOrder,
      orderTotal,
      paymentHeading,
      paymentsPaymentMethodsFlag,
      paymentSubheading,
      paymentSalesTaxFlag,
      salesTaxBillingAddressId,
      stripeSalesTaxCalculationId,
      taxAmountExclusiveInCents,
      startInterstitialTimeout,
      unpaidAdditionalChargeOrder,
    };
  },
  data() {
    return {
      loaded: false,
      canceling: false,
      cancelingError: '',
      showDeclineConfirm: false,
      isOrderProcessing: false,
      showInterstitial: false,
      AvoToast60: AvoToast60,
    };
  },
  computed: {
    products() {
      return (
        this.unpaidAdditionalChargeOrder?.reservation_line_items?.map(rli => {
          return {
            ...rli.product,
            quantity: rli.quantity,
            price_info: {
              ...rli.product.price_info,
              discounted_rate:
                rli.product.price_info?.discounted_rate ||
                (rli.effective_percent_discount
                  ? rli.product.rate * rli.quantity -
                    rli.effective_percent_discount
                  : 0),
            },
          };
        }) ?? []
      );
    },
    canViewPaymentInfo() {
      return this.currentAccountId === this.currentOrder?.account?.id;
    },
  },
  methods: {
    ...mapActions('reservation', [
      'loadAdditionalChargeOrder',
      'payAdditionalChargeOrder',
    ]),
    ...mapActions('account', ['cancelOrder']),
    ...mapActions('currentUser', ['loadUnpaidAdditionalCharges']),
    showModal() {
      this.showDeclineConfirm = true;
    },
    closeModal() {
      this.showDeclineConfirm = false;
      this.$router.push(
        `/account/${this.unpaidAdditionalChargeOrder.account.id}`
      );
    },
    tierAttributes(product) {
      let attributes = [
        `$${Number(product.rate)}/credit`,
        `${product.min} - ${product.max} photo credits`,
      ];
      if (Number(product.rate) < 39) {
        attributes.push('account rep');
      }
      attributes = attributes.concat(
        'free premium edits',
        'preferred scheduling'
      );
      return attributes;
    },
    placeOrder: async function (stripe, card, saveCard) {
      this.isOrderProcessing = true;
      this.paymentError = '';
      try {
        const options = {
          orderId: this.orderId,
          stripe: stripe,
          card:
            !this.hasShopifyIntegration &&
            this.orderTotal > 0 &&
            !this.isInternal
              ? card
              : null,
          saveCard: saveCard,
          paymentsFlag: this.paymentsPaymentMethodsFlag,
          salesTaxFlag: this.paymentSalesTaxFlag,
          salesTax: {
            soona_billing_address_id: this.salesTaxBillingAddressId,
            id: this.stripeSalesTaxCalculationId,
            tax_amount_exclusive: this.taxAmountExclusiveInCents,
          },
        };

        const [response, error] = await this.payAdditionalChargeOrder(options);

        this.loadUnpaidAdditionalCharges(this.currentOrder.account.id);
        if (error) {
          this.isOrderProcessing = false;
          this.paymentError = error.data.message;
        } else if (response.confirmation_url) {
          window.top.location.href = response.confirmation_url;
        } else {
          this.showInterstitial = true;
          this.startInterstitialTimeout();
        }
      } catch (error) {
        this.isOrderProcessing = false;
        this.paymentError = error.data.message;
        Sentry.captureException(new Error('failed to process order'), {
          extra: error.data,
        });
        throw error.data;
      }
    },
    previousPage() {
      this.$router.back();
    },
    handleCancel() {
      this.canceling = true;
      this.cancelingError = '';
      this.cancelOrder({
        accountId: this.currentOrder.account.id,
        orderId: this.unpaidAdditionalChargeOrder.id,
      })
        .then(([, error]) => {
          this.loadUnpaidAdditionalCharges(this.currentOrder.account.id);
          if (error) {
            this.cancelingError = error.message;
          } else {
            this.showModal();
          }
          this.canceling = false;
        })
        .catch(error => {
          this.canceling = false;
          this.cancelingError = error.message;
          Sentry.captureException(new Error('failed to cancel order'), {
            extra: error,
          });
        });
    },
    toCurrency(value, currency = 'USD') {
      return value?.toLocaleString('en-US', {
        style: 'currency',
        currency,
      });
    },
  },
};
</script>
<style lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.AdditionalChargeOrderSummary {
  .credit-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__disclaimer {
      @include variables_fonts.u-label--small;
    }

    &__header {
      @include variables_fonts.u-subheader--heavy;
    }

    hr {
      border: 0.0625rem solid rgba(134, 134, 134, 0.5);
      height: 0;
      margin: 0;
    }

    .attribute {
      @include variables_fonts.u-body--regular;
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }

  .confirmation-modal-card {
    max-width: 90vw !important;
    border-radius: 7px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: variables.$white-default;
    padding: 2em;
  }

  .soona-error {
    margin-bottom: 1rem;
  }
}
</style>
