<script setup>
import { ref, computed, onMounted } from 'vue';

import AITemplates from './AITemplates.vue';
import SoonaFaq from 'src/components/ui_library/SoonaFaq.vue';
import faqData from './faq.json';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useAccount } from '@/composables/useAccount';
import RecentUploads from './RecentUploads.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useTitle } from '@vueuse/core';
import SubscriptionBannerMediaEditor from './SubscriptionBannerMediaEditor.vue';
import { useTiers } from '@/composables/subscription/useTiers';
import { useFlag } from '@/composables/useFlag';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import UploadMediaDialog from '@/components/account_gallery/UploadMediaDialog.vue';
import { useRoute, useRouter } from 'vue-router';
import EditorTools from '@/components/media-editor/EditorTools.vue';
import { useStorageUsage } from '@/queries/account/useStorageUsage';
import { toMimeTypes } from '@/lib/mime-type-mapping';
import { useUploader } from '@/composables/useUploader';
import { useCreateDigitalAsset } from '@/queries/digital_assets/useCreateDigitalAsset';
import SoonaProgress from '@/components/ui_library/SoonaProgress.vue';
import UploadProgressDialog from '@/components/media-editor/UploadProgressDialog.vue';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import PopularAIScenes from '@/components/media-editor/PopularAIScenes.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
});

const isRedirecting = ref(false);
const showUploadDialog = ref(false);
const activeUploadsLocal = ref([]);
const cancelUploadsLocal = ref(() => {});
const maxConcurrent = ref(3);
const uploadInterval = ref(100);
const fileSizeLimit = ref(50);
const isDragover = ref(false);
const alertMessage = ref('');
const errorMessage = ref('');
const acceptedFileTypes = ref('.png,.jpg,.jpeg');
const initialTool = ref('');
const initialToolCaption = ref(null);

const aiTemplatesFlag = useFlag('toaster_ai_templates');
const apolloTrialForEveryoneFlag = useFlag('apollo_trial_for_everyone');

const accountId = computed(() => props.accountId);

const route = useRoute();
const router = useRouter();
const { addToast } = useSoonaToast();

const { pageViewed, linkClicked } = useBaseEvents();
onMounted(() => {
  pageViewed();
});

useTitle('AI studio | soona');

const {
  account: currentAccount,
  isDigitalSubscriber,
  eligibleForPlatformSubscriptionTrial,
} = useAccount(accountId);

const { data: accountStorage, error: availStorageFetchError } =
  useStorageUsage(accountId);

const { mutate: createDigitalAsset, error: createError } =
  useCreateDigitalAsset(accountId);

const acceptArray = computed(() =>
  acceptedFileTypes.value.split(',').map(item => item.trim())
);

const offerTrial = computed(
  () =>
    apolloTrialForEveryoneFlag.value &&
    eligibleForPlatformSubscriptionTrial.value
);

function validateNotCR2(file) {
  if (file.name.toLowerCase().endsWith('.cr2'))
    return 'cannot upload RAW (CR2) files here';
}

function redirectToMediaEditor(assetId) {
  isRedirecting.value = true;

  const routerDestination = {
    name: 'uploads-assets-media-view',
    params: {
      accountId: accountId.value,
      digitalAssetId: assetId,
    },
    query: {
      ...route.query,
    },
  };

  if (initialTool.value) {
    routerDestination.query = { tool: initialTool.value };
  }

  router.push(routerDestination);
}

const showAlertOrError = (type = 'alert', message) => {
  if (initialTool.value) {
    // alert/error is from tool specific uploading, so use toast
    addToast(message, {
      variation: type,
    });
    return;
  }

  if (type === 'error') {
    errorMessage.value = message;
    return;
  }

  alertMessage.value = message;
};

const handleUploadClick = () => {
  linkClicked({
    context: 'media editor',
    subContext: 'landing page',
    linkLabel:
      initialToolCaption.value !== ''
        ? initialToolCaption.value
        : 'upload media',
    linkHref: null,
  });
};

const handleUploadError = errMsg => {
  cancelUploadsLocal.value();
  showAlertOrError('error', errMsg);
};

const handleUploadComplete = blob => {
  const digitalAsset = {
    asset_type: 'original',
    file: blob.signed_id,
    title: blob.filename,
    media_type: blob.content_type.startsWith('image') ? 'photo' : 'video',
    ownership: 'customer',
    origin: 'customer',
    origin_source: 'manual_upload',
    visibility: 'all',
  };

  createDigitalAsset(digitalAsset, {
    onSuccess: data => {
      if (activeUploadsLocal.value.length === 0 && data.id) {
        handleUploadClick();
        redirectToMediaEditor(data.id);
      }
    },
  });
};

const remainingStorageBytes = computed(() => {
  if (accountStorage.value) {
    return (
      accountStorage.value?.total_available_storage -
      accountStorage.value?.total_used_storage
    );
  }

  return 0;
});

const {
  handleUpload,
  handleDrop,
  activeUploads,
  percentComplete: totalPercentComplete,
  cancelUploads,
} = useUploader(
  maxConcurrent,
  uploadInterval,
  fileSizeLimit,
  validateNotCR2,
  handleUploadError,
  handleUploadComplete,
  remainingStorageBytes
);

activeUploadsLocal.value = activeUploads.value;
cancelUploadsLocal.value = cancelUploads;

const { basicTier } = useTiers();

const trackClick = label => {
  linkClicked({
    context: route.meta.context,
    subContext: 'header',
    linkLabel: label,
    linkHref: null,
  });
};

const handleOpenUploadDialog = () => {
  trackClick('add media');
  showUploadDialog.value = true;
};
const handleCloseUploadDialog = () => {
  trackClick('cancel');
  showUploadDialog.value = false;
};

const handleDroppedFiles = (e, tool = '', toolCaption = '') => {
  // don’t allow drag-and-drop upload if available storage check is still loading
  if (!accountStorage.value) return;

  isDragover.value = false;
  alertMessage.value = '';
  errorMessage.value = '';
  initialTool.value = tool;
  initialToolCaption.value = toolCaption;

  // only check validity for tool uploads, since SoonaUploadForm checks in the general upload section up top
  if (tool !== '') {
    const hasInvalidFileType = [...e.dataTransfer.files].some(file => {
      if (!toMimeTypes(acceptArray.value).includes(file.type)) {
        showAlertOrError(
          'alert',
          `only ${acceptArray.value.join(', ')} files allowed`
        );
        return true;
      }
    });

    if (hasInvalidFileType) return;
  }

  handleDrop(e);
};

const completedUploads = computed(() =>
  activeUploads.value.filter(upload => upload.completed)
);

const isUploading = computed(() => activeUploads.value.length > 0);

const isEditorTransition = computed(
  () =>
    (totalPercentComplete.value === 100 || isRedirecting.value) &&
    !createError.value
);

const handleCancelUploads = () => {
  if (completedUploads.value.length > 0) {
    cancelUploads();
  } else {
    cancelUploads();
    addToast('upload canceled', {
      variation: 'info',
      iconName: 'xmark',
    });
  }
};

const priorityError = usePriorityError(
  createError,
  availStorageFetchError,
  errorMessage
);
</script>

<template>
  <div class="media-editor-landing-page">
    <div class="me-landing__tool">
      <SubscriptionBannerMediaEditor
        v-if="!!currentAccount && !isDigitalSubscriber"
        :tier="basicTier"
        :cta-copy="offerTrial ? 'try it free for 7 days' : 'upgrade'"
      />
      <header class="me-landing__header">
        <div class="me-landing__header__wrapper">
          <div class="me-landing__header__copy">
            <div class="me-landing__header__title">
              <h1 class="u-headline--heavy">AI studio</h1>
              <SoonaIcon name="wand-magic-sparkles" />
            </div>
            <div>
              <SoonaButton
                variation="solid-black"
                size="medium"
                @on-click="handleOpenUploadDialog"
              >
                <SoonaIcon name="plus-large" /> add media
              </SoonaButton>
            </div>
          </div>
          <p class="u-subheader--regular">
            create unique scenes with AI or enhance any photo using our editing
            tools.
          </p>
        </div>
        <div class="me-landing__header__section">
          <AITemplates v-if="aiTemplatesFlag" :account-id="accountId" />
          <EditorTools
            hide-header
            :is-loading="!accountStorage"
            @tool-file-browse-upload="
              (e, tool, toolCaption) => {
                alertMessage = '';
                errorMessage = '';
                initialTool = tool;
                initialToolCaption = toolCaption;
                handleUpload(e);
              }
            "
            @tool-file-drop-upload="
              (e, tool, toolCaption) => {
                handleDroppedFiles(e, tool, toolCaption);
              }
            "
          />
        </div>
      </header>
      <template>
        <SoonaAlert
          v-if="alertMessage && !isEditorTransition"
          class="da-upload__notification"
        >
          {{ alertMessage }}
        </SoonaAlert>
        <SoonaError
          v-if="errorMessage || priorityError"
          class="da-upload__notification"
        >
          {{ errorMessage || priorityError }}
        </SoonaError>
      </template>
      <PopularAIScenes :account-id="accountId" />
      <RecentUploads :account-id="accountId" />
    </div>

    <SoonaFaq class="faq" :faq-tab-list="faqData" />
    <UploadMediaDialog
      v-if="showUploadDialog"
      :account-id="accountId"
      @close="handleCloseUploadDialog"
    />
    <UploadProgressDialog v-if="isUploading || isEditorTransition">
      <div class="da-upload__progresses">
        <em class="u-subheader--heavy da-upload__progresses-title">
          {{
            isEditorTransition ? 'done! 🎉 opening AI studio…' : 'uploading…'
          }}
        </em>
        <SoonaProgress
          v-for="(upload, index) in activeUploads"
          :key="index"
          class="da-upload__progress"
          :error="upload.error"
          :is-waiting="!upload.activated"
          :value="(upload.tracker?.loaded / upload.tracker?.total ?? 0) * 100"
        />
      </div>
      <SoonaButton
        v-if="isUploading"
        variation="tertiary"
        size="medium"
        @on-click="handleCancelUploads"
      >
        <template
          v-if="completedUploads.length > 0 && activeUploads.length > 1"
        >
          cancel incomplete upload(s)
        </template>
        <template v-else>cancel upload</template>
      </SoonaButton>
    </UploadProgressDialog>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.me-landing {
  &__tool {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.375rem;
    }

    &__section {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }

    &__copy {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}

.media-editor-landing-page {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;

  .faq {
    margin-top: 0rem;
    min-width: 100%;
  }

  .image-instructions__title {
    @include variables_fonts.u-title--heavy;
  }

  @media (min-width: variables.$screen-sm-min) {
    gap: 4.5rem;
  }
}
</style>
